import { AxiosError } from 'axios';
import { call, put } from 'redux-saga/effects';
import { PayloadAction, PayloadActionCreator } from '@reduxjs/toolkit';
import { startLoading, finishLoading } from 'store/reducers/loading';
import { IResponse, ITShopbyErrorRes } from '@types';
import { IErrorPayloadWithKey } from 'store/types';
import { openErrorModal } from 'store/reducers/common';
import { ERROR } from 'constants/globalPhrases';
import Logger from 'utils/logger';

export function* fetchApi<P, T>(
  api: any,
  requestActionType: string,
  successAction: PayloadActionCreator<T>,
  failureAction: PayloadActionCreator<AxiosError>,
  payload: P,
) {
  yield put(startLoading(requestActionType));
  try {
    const data: Promise<any> = yield call(api, payload);
    yield put(successAction(data));
  } catch (e: any) {
    if (e.response.status === 429) {
      window.location.replace('/error');
    }
    yield put(failureAction(e));
  }
  yield put(finishLoading(requestActionType));
}

// 최대 재시도 횟수 설정
const maxRetries = 3;
const shopbyApiQueue: any = [];

// 요청을 구분하기 위한 ID 생성 함수
const generateId = () => Math.random().toString(30).substr(2, 6) + Date.now();

export function createShopbyFetchAction<P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  errorAction: PayloadActionCreator<ITShopbyErrorRes>,
  params?: any,
  successFunc?: any,
  errorFunc?: any,
) {
  return function* fetchApi(action: PayloadAction<P>) {
    const requestId = generateId(); // 요청 ID 생성
    const timestamp = new Date(); // 타임스탬프 생성

    shopbyApiQueue.push({
      api,
      successAction,
      errorAction,
      params,
      successFunc,
      errorFunc,
      action,
      id: requestId,
      timestamp, // 타임스탬프 추가
    });

    Logger.info('Request added to queue', 'createShopbyFetchAction', {
      requestId,
      timestamp,
      actionType: action.type,
      payload: action.payload,
    }); // 로그 추가

    // NOTE: 처음 시작일 경우, start하는 로직. 기존에 돌고 있는 큐가 있다면 큐 대기 배열에 추가만 한다.
    if (shopbyApiQueue.length === 1) {
      Logger.info('shopbyApiQueue start', 'createShopbyFetchAction', {
        requestId,
        timestamp: Date.now(),
        actionType: action.type,
        payload: action.payload,
        shopbyApiQueue,
      }); // 로그 추가
      yield* shopbyApiRequest();
    } else if (shopbyApiQueue.length > 1) {
      const allNotRunning = shopbyApiQueue.every(
        (item: { isRunning: any }) => !item.isRunning,
      );
      if (allNotRunning) {
        console.error(
          `Queue is currently NOT running. Force Run... Current Queue:`,
          {
            shopbyApiQueue: shopbyApiQueue.map(
              (item: {
                id: any;
                action: { type: any; payload: any };
                isRunning: any;
                timestamp: any;
              }) => ({
                id: item.id,
                actionType: item.action?.type,
                payload: item.action?.payload,
                isRunning: item.isRunning,
                timestamp: item.timestamp,
              }),
            ),
          },
        );
        yield* shopbyApiRequest();
      }
    }
  };
}

const shopbyApiRequest = function* (retries = 0): Generator<any, void, any> {
  const item = shopbyApiQueue[0];
  const {
    api,
    successAction,
    errorAction,
    params,
    successFunc,
    errorFunc,
    action,
    id,
  } = item;
  const { type, payload } = action;
  yield put(startLoading(type));

  try {
    Logger.info('Request try', 'shopbyApiRequest', {
      requestId: id,
      timestamp: Date.now(),
      actionType: action.type,
      payload: action.payload,
    }); // 로그 추가

    const data: Promise<any> = yield call(
      api,
      params ? { ...params, ...payload } : payload,
    );

    shopbyApiQueue.shift();

    Logger.info('Request succeeded', 'shopbyApiRequest', {
      requestId: id,
      timestamp: Date.now(),
      actionType: action.type,
      data,
    }); // 성공 로그 추가

    yield put(successAction({ data, requestPayload: payload }));

    if (successFunc) {
      yield call<typeof successFunc>(successFunc, data, payload);
    }

    if (shopbyApiQueue.length > 0) {
      Logger.info('Request left queue item', 'shopbyApiRequest', {
        timestamp: Date.now(),
      });
      yield* shopbyApiRequest();
    }
  } catch (e: any | AxiosError<ITShopbyErrorRes>) {
    Logger.warn('Request failed', 'shopbyApiRequest', {
      requestId: id,
      timestamp: Date.now(),
      actionType: action.type,
      error: e,
    }); // 실패 로그 추가

    if (e.response?.status === 401) {
      localStorage.removeItem('shopbyAccessToken');

      Logger.warn('Request failed', 'shopbyApiRequest', {
        requestId: id,
        errorStatus: e.response?.status,
        actionType: action.type,
      }); // 실패 로그 추가

      // 최대 재시도 횟수보다 적으면 재시도
      if (retries < maxRetries) {
        Logger.warn('Request retries', 'shopbyApiRequest', {
          requestId: id,
          actionType: action.type,
          retries,
        }); // 실패 로그 추가
        yield* shopbyApiRequest(retries + 1);
      } else {
        Logger.warn('Request over Max retries', 'shopbyApiRequest', {
          requestId: id,
          actionType: action.type,
          retries,
        }); // 실패 로그 추가
        shopbyApiQueue.length = 0;
        yield put(openErrorModal({ contents: ERROR.COMMON }));
      }
    } else {
      shopbyApiQueue.shift();

      if (shopbyApiQueue.length > 0) {
        Logger.info('Keep going Request left queue', 'shopbyApiRequest', {
          requestId: id,
          actionType: action.type,
          retries,
        });

        yield* shopbyApiRequest();
      }

      if (!e.response) {
        console.warn('* shopby Front Error In Saga ::\n', e);
        yield put(errorAction(e.toString()));
        yield put(finishLoading(type));
        return;
      }

      yield put(errorAction(e.response?.data || e.response));

      if (errorFunc) {
        yield call<typeof errorFunc>(errorFunc, e.response?.data || e);
      }
    }
  } finally {
    // isRunning 상태 업데이트
    item.isRunning = false;
  }
  yield put(finishLoading(type));
};

const shopbyApiQueueWithoutRequestPayload: any = [];
export function createShopbyFetchActionWithoutRequestPayload<P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  errorAction: PayloadActionCreator<ITShopbyErrorRes>,
  params?: any,
  successFunc?: any,
  errorFunc?: any,
) {
  return function* fetchApi(action: PayloadAction<P>) {
    const requestId = generateId(); // 요청 ID 생성
    const timestamp = Date.now(); // 타임스탬프 생성

    shopbyApiQueueWithoutRequestPayload.push({
      api,
      successAction,
      errorAction,
      params,
      successFunc,
      errorFunc,
      action,
      id: requestId, // 요청 ID 추가
      timestamp, // 타임스탬프 추가
      isRunning: false, // isRunning 추가
    });

    Logger.info(
      'Request added to queue (without payload)',
      'createShopbyFetchActionWithoutRequestPayload',
      {
        requestId,
        timestamp: Date.now(),
        actionType: action.type,
        payload: action.payload,
      },
    ); // 로그 추가

    // NOTE: 처음 시작일 경우, start하는 로직. 기존에 돌고 있는 큐가 있다면 큐 대기 배열에 추가만 한다.
    if (shopbyApiQueueWithoutRequestPayload.length === 1) {
      yield* shopbyApiRequestWithoutRequestPayload();
    } else if (shopbyApiQueueWithoutRequestPayload.length > 1) {
      const allNotRunning = shopbyApiQueueWithoutRequestPayload.every(
        (item: { isRunning: any }) => !item.isRunning,
      );
      if (allNotRunning) {
        console.error(
          `Queue is currently NOT running. Force Run... Current Queue:`,
          {
            shopbyApiQueueWithoutRequestPayload:
              shopbyApiQueueWithoutRequestPayload.map(
                (item: {
                  id: any;
                  action: { type: any; payload: any };
                  isRunning: any;
                  timestamp: any;
                }) => ({
                  id: item.id,
                  actionType: item.action?.type,
                  payload: item.action?.payload,
                  isRunning: item.isRunning,
                  timestamp: item.timestamp,
                }),
              ),
          },
        );
        yield* shopbyApiRequestWithoutRequestPayload();
      }
    }
  };
}

const shopbyApiRequestWithoutRequestPayload = function* (
  retries = 0,
): Generator<any, void, any> {
  const item = shopbyApiQueueWithoutRequestPayload[0];
  const {
    api,
    successAction,
    errorAction,
    params,
    successFunc,
    errorFunc,
    action,
    id, // 요청 ID
  } = item;
  const { type, payload } = action;
  yield put(startLoading(type));

  // isRunning 상태 업데이트
  item.isRunning = true;

  try {
    Logger.info(
      'Request started (without payload)',
      'shopbyApiRequestWithoutRequestPayload',
      {
        requestId: id,
        timestamp: Date.now(),
        actionType: action.type,
        payload: action.payload,
      },
    ); // 요청 시작 로그

    const data: Promise<any> = yield call(
      api,
      params ? { ...params, ...payload } : payload,
    );

    yield put(successAction(data));
    if (successFunc) {
      yield call<typeof successFunc>(successFunc, data, payload);
    }

    shopbyApiQueueWithoutRequestPayload.shift();

    Logger.info(
      'Request succeeded (without payload)',
      'shopbyApiRequestWithoutRequestPayload',
      {
        requestId: id,
        timestamp: Date.now(),
        actionType: action.type,
        data,
      },
    ); // 성공 로그 추가

    if (shopbyApiQueueWithoutRequestPayload.length > 0) {
      Logger.info(
        'Request left queue item',
        'shopbyApiRequestWithoutRequestPayload',
        {
          timestamp: Date.now(),
        },
      );
      yield* shopbyApiRequestWithoutRequestPayload();
    }
  } catch (e: any | AxiosError<ITShopbyErrorRes>) {
    Logger.warn(
      'Request failed (without payload)',
      'shopbyApiRequestWithoutRequestPayload',
      {
        requestId: id,
        timestamp: Date.now(),
        actionType: action.type,
        error: e,
      },
    ); // 실패 로그 추가

    if (e.response?.status === 401) {
      localStorage.removeItem('shopbyAccessToken');
      if (retries < maxRetries) {
        yield* shopbyApiRequestWithoutRequestPayload(retries + 1);
      } else {
        yield put(openErrorModal({ contents: ERROR.COMMON }));
        shopbyApiQueueWithoutRequestPayload.length = 0;
      }
    } else {
      yield put(errorAction(e.response?.data || e));
      shopbyApiQueueWithoutRequestPayload.shift();

      if (shopbyApiQueueWithoutRequestPayload.length > 0) {
        Logger.info(
          'Keep going Request left queue',
          'shopbyApiRequestWithoutRequestPayload',
          {
            requestId: id,
            actionType: action.type,
            retries,
          },
        ); // 실패 로그 추가
        yield* shopbyApiRequestWithoutRequestPayload();
      }
    }
  } finally {
    // isRunning 상태 업데이트
    item.isRunning = false;
  }
  yield put(finishLoading(type));
};

const shopbyApiQueueWithKey: any = [];
export function createShopbyFetchActionWithKey<P, T>(
  api: any,
  successAction: PayloadActionCreator<{
    key: any;
    data: T;
    requestPayload: P;
  }>,
  errorAction: PayloadActionCreator<{
    key: any;
    error: any | ITShopbyErrorRes | AxiosError<ITShopbyErrorRes>;
  }>,
  params?: any,
  successFunc?: any,
  errorFunc?: any,
) {
  return function* fetchApi(action: PayloadAction<{ key: any; payload: any }>) {
    const requestId = generateId(); // 요청 ID 생성
    const timestamp = Date.now(); // 타임스탬프 생성

    shopbyApiQueueWithKey.push({
      api,
      successAction,
      errorAction,
      params,
      successFunc,
      errorFunc,
      action,
      id: requestId, // 요청 ID 추가
      timestamp, // 타임스탬프 추가
      isRunning: false, // isRunning 추가
    });

    Logger.info(
      'Request added to queue (with key)',
      'createShopbyFetchActionWithKey',
      {
        requestId,
        timestamp,
        actionType: action.type,
        payload: action.payload,
      },
    ); // 로그 추가

    // NOTE: 처음 시작일 경우, start하는 로직. 기존에 돌고 있는 큐가 있다면 큐 대기 배열에 추가만 한다.
    if (shopbyApiQueueWithKey.length === 1) {
      yield* shopbyApiRequestWithKey();
    } else if (shopbyApiQueueWithKey.length > 1) {
      const allNotRunning = shopbyApiQueueWithKey.every(
        (item: { isRunning: any }) => !item.isRunning,
      );
      if (allNotRunning) {
        console.error(
          `Queue is currently NOT running. Force Run... Current Queue:`,
          {
            shopbyApiQueueWithKey: shopbyApiQueueWithKey.map(
              (item: {
                id: any;
                action: { type: any; payload: any };
                isRunning: any;
                timestamp: any;
              }) => ({
                id: item.id,
                actionType: item.action?.type,
                payload: item.action?.payload,
                isRunning: item.isRunning,
                timestamp: item.timestamp,
              }),
            ),
          },
        );
        yield* shopbyApiRequestWithKey();
      }
    }
  };
}

const shopbyApiRequestWithKey = function* (
  retries = 0,
): Generator<any, void, any> {
  const item = shopbyApiQueueWithKey[0];
  const {
    api,
    successAction,
    errorAction,
    params,
    successFunc,
    errorFunc,
    action,
    id, // 요청 ID
  } = item;
  const { type, payload } = action;
  const { key, payload: requestPayload } = payload;
  yield put(startLoading(type));

  // isRunning 상태 업데이트
  item.isRunning = true;

  try {
    Logger.info('Request started (with key)', 'shopbyApiRequestWithKey', {
      requestId: id,
      timestamp: Date.now(),
      actionType: action.type,
      payload: action.payload,
    }); // 요청 시작 로그

    const data: Promise<any> = yield call(
      api,
      params ? { ...params, ...requestPayload } : requestPayload,
    );

    yield put(successAction({ key, data, requestPayload }));
    if (successFunc) {
      yield call<typeof successFunc>(successFunc, data, requestPayload, key);
    }

    shopbyApiQueueWithKey.shift();

    Logger.info('Request succeeded (with key)', 'shopbyApiRequestWithKey', {
      requestId: id,
      timestamp: Date.now(),
      actionType: action.type,
      payload: action.payload,
    }); // 성공 로그 추가

    if (shopbyApiQueueWithKey.length > 0) {
      Logger.info('Request left queue item', 'shopbyApiRequestWithKey', {
        timestamp: Date.now(),
      });
      yield* shopbyApiRequestWithKey();
    }
  } catch (e: any | ITShopbyErrorRes | AxiosError<ITShopbyErrorRes>) {
    Logger.warn('Request failed (with key)', 'shopbyApiRequestWithKey', {
      requestId: id,
      timestamp: Date.now(),
      actionType: action.type,
      error: e,
    }); // 실패 로그 추가

    if (e.response?.status === 401) {
      localStorage.removeItem('shopbyAccessToken');
      if (retries < maxRetries) {
        yield* shopbyApiRequestWithKey(retries + 1);
      } else {
        yield put(openErrorModal({ contents: ERROR.COMMON }));
        shopbyApiQueueWithKey.length = 0;
      }
    } else {
      shopbyApiQueueWithKey.shift();

      if (shopbyApiQueueWithKey.length > 0) {
        Logger.info(
          'Keep going Request left queue',
          'shopbyApiRequestWithKey',
          {
            requestId: id,
            actionType: action.type,
            retries,
          },
        ); // 실패 로그 추가
        yield* shopbyApiRequestWithKey();
      }
      yield put(errorAction({ key, error: e.response?.data || e }));
      if (errorFunc) {
        yield call<typeof errorFunc>(errorFunc, e.response?.data || e);
      }
    }
  } finally {
    // isRunning 상태 업데이트
    item.isRunning = false;
  }

  yield put(finishLoading(type));
};

export const createV2FetchAction = <P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  failureAction: PayloadActionCreator<AxiosError>,
  params?: any,
  successFunc?: any,
  failureFunc?: any,
) => {
  return function* fetchApi(action: PayloadAction<P>) {
    const { type, payload } = action;

    yield put(startLoading(type));

    try {
      const data: Promise<any> = yield call(
        api,
        params ? { ...params, ...payload } : payload,
      );
      yield put(successAction(data));

      if (successFunc) {
        yield call<typeof successFunc>(successFunc, data, payload);
      }
    } catch (e: any) {
      if (e.response.status === 429) {
        window.location.replace('/error');
      }
      yield put(failureAction(e));
      if (failureFunc) {
        yield call<typeof failureFunc>(failureFunc, e);
      }
    }
    yield put(finishLoading(type));
  };
};

export const createV2FetchFuncWithStringError = <P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  failureAction: PayloadActionCreator<Error | string>,
  params?: any,
  successFunc?: any,
  failureFunc?: any,
) => {
  return function* fetchApi(action: PayloadAction<P>) {
    const { type, payload } = action;

    yield put(startLoading(type));
    try {
      const data: T & IResponse = yield call(
        api,
        params ? { ...params, ...payload } : payload,
      );
      const { status, message } = data;

      if (status && status === 'fail') {
        if (message) {
          yield put(failureAction(message));
        }

        if (failureFunc) {
          yield call<typeof failureFunc>(failureFunc, data);
        }
      } else {
        yield put(successAction(data));

        if (successFunc) {
          yield call<typeof successFunc>(successFunc, data, payload);
        }
      }
    } catch (e: any) {
      if (e.response.status === 429) {
        window.location.replace('/error');
      }

      yield put(failureAction(e));

      if (failureFunc) {
        yield call<typeof failureFunc>(failureFunc, e);
      }
    }
    yield put(finishLoading(type));
  };
};

export const createFetchAction = <P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  failureAction: PayloadActionCreator<Error | string>,
  successFunc?: any,
  failureFunc?: any,
) => {
  return function* fetchApi(action: PayloadAction<P>) {
    const { type, payload } = action;

    yield put(startLoading(type));
    try {
      const data: T & IResponse = yield call(api, payload);
      const { status, message } = data;

      if (status && status === 'fail') {
        if (message) {
          yield put(failureAction(message));
        }

        if (failureFunc) {
          yield call<typeof failureFunc>(failureFunc, data);
        }
      } else {
        yield put(successAction(data));

        if (successFunc) {
          yield call<typeof successFunc>(successFunc, data);
        }
      }
    } catch (error: any) {
      if (error.response?.status === 429) {
        window.location.replace('/error');
      }

      yield put(failureAction(error));

      if (failureFunc) {
        yield call<typeof failureFunc>(failureFunc, error);
      }
    }
    yield put(finishLoading(type));
  };
};
export const createFetchActionForSequence = <P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  failureAction: PayloadActionCreator<Error | string>,
  successFunc?: any,
  failureFunc?: any,
) => {
  return function* fetchApi(action: PayloadAction<P>) {
    const { type, payload } = action;

    yield put(startLoading(type));
    try {
      const data: T & IResponse = yield call(api, payload);
      const { status, message } = data;

      if (status && status === 'fail') {
        if (message) {
          yield put(failureAction(message));
        }

        if (failureFunc) {
          yield call<typeof failureFunc>(failureFunc, data);
        }
      } else {
        yield put(successAction(data));

        if (successFunc) {
          yield call<typeof successFunc>(successFunc, data);
        }
      }

      yield put(finishLoading(type));
      return data;
    } catch (error: any) {
      if (error.response?.status === 429) {
        window.location.replace('/error');
        return;
      }

      if (failureFunc) {
        yield call<typeof failureFunc>(failureFunc, error);
      }
      yield put(finishLoading(type));
      throw error;
    }
  };
};

export const createFetchActionWithFailResult = <P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  failureAction: PayloadActionCreator<T>,
  errorAction?: PayloadActionCreator<Error | string>,
  successFunc?: any,
  failureFunc?: any,
  errorFunc?: any,
) => {
  return function* fetchApi(action: PayloadAction<any>) {
    const { type, payload } = action;

    yield put(startLoading(type));
    try {
      const data: T & IResponse = yield call(api, payload);
      const { status, message } = data;

      if (status && status === 'fail') {
        yield put(failureAction(data));

        if (payload.failureFunc) {
          yield call<typeof failureFunc>(failureFunc, data);
        }
      } else {
        yield put(successAction(data));

        if (payload.successFunc) {
          yield call<typeof successFunc>(successFunc, data);
        }
      }
    } catch (e: any) {
      if (e.response.status === 429) {
        window.location.replace('/error');
      }

      if (errorAction) {
        yield put(errorAction(e));
      }

      if (errorFunc) {
        yield call<typeof errorFunc>(errorFunc, e);
      }
    }
    yield put(finishLoading(type));
  };
};

export const createActionWithFailResult = <P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  failureAction: PayloadActionCreator<T>,
  errorAction?: PayloadActionCreator<Error | string>,
  successFunc?: any,
  failureFunc?: any,
  errorFunc?: any,
) => {
  return function* fetchApi(action: PayloadAction<any>) {
    const { type, payload } = action;

    yield put(startLoading(type));
    try {
      const data: T & IResponse = yield call(api, payload);
      const { status, message } = data;

      if (status && status === 'fail') {
        yield put(failureAction(data));

        if (payload.failureFunc) {
          yield call<typeof failureFunc>(failureFunc, data);
        }
      } else {
        yield put(successAction(data));

        if (payload.successFunc) {
          yield call<typeof successFunc>(successFunc, data);
        }
      }
    } catch (e: any) {
      if (e.response.status === 429) {
        window.location.replace('/error');
      }

      if (errorAction) {
        yield put(errorAction(e));
      }

      if (errorFunc) {
        yield call<typeof errorFunc>(errorFunc, e);
      }
    }
    yield put(finishLoading(type));
  };
};

/** NOTE:
 * fail, error 등의 상태 값을 따로 리덕스 스토어 내에서 전역적으로 관리할 필요 없을 때 사용
 * 예) 서버 결과값에 따라 모달창으로 안내 메세지만 표출하는 로직일 경우 */

export const createSimpleAction = <P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  failureAction?: PayloadActionCreator<T>,
  errorAction?: PayloadActionCreator<Error | AxiosError | any>,
) => {
  return function* fetchApi(action: PayloadAction<any>) {
    const { type, payload } = action;

    yield put(startLoading(type));

    try {
      const data: T & IResponse = yield call(api, payload);
      const { status, message } = data;

      if (status === 'fail') {
        if (failureAction) {
          yield put(failureAction(data));
        }

        if (payload.functions.onFail) {
          yield call<typeof payload.functions.onFail>(
            payload.functions.onFail,
            message,
          );
        }
      } else {
        yield put(successAction(data));

        if (payload.functions.onSuccess) {
          yield call<typeof payload.functions.onSuccess>(
            payload.functions.onSuccess,
            data,
          );
        }
      }
    } catch (e: any) {
      if (e.response?.status === 429) {
        window.location.replace('/error');
      }

      if (errorAction) {
        yield put(errorAction(e));
      }

      if (payload.functions.onError) {
        yield call<typeof payload.functions.onError>(
          payload.functions.onError,
          e,
        );
      }
    }
    yield put(finishLoading(type));
  };
};

// export const createActionWithBootpay = <P, T>(
//   api: any,
//   successAction: PayloadActionCreator<T>,
//   failureAction: PayloadActionCreator<ISelfAuthenticationError>,
//   successFunc?: any,
//   failureFunc?: any,
// ) => {
//   return function* fetchApi(action: PayloadAction<any>) {
//     const { type, payload } = action;
//     const { params } = payload;

//     yield put(startLoading(type));
//     try {
//       const response: ISelfAuthenticationPayload = yield call(api, params);

//       switch (response.event) {
//         case 'done':
//           // 완료, 성공시 done 이벤트만 받는다 (만약 분리승인 옵션을 줬을 경우 confirm 도 수신하게 됨)
//           yield put(successAction(response.data));
//           if (payload.successFunc) {
//             yield call<typeof successFunc>(successFunc, response.data);
//           }
//           break;
//         default:
//           console.log(response.data);
//       }
//     } catch (e: any) {
//       if (failureFunc) {
//         yield put(failureAction(e));
//       }

//       if (failureFunc) {
//         yield call<typeof failureFunc>(failureFunc, e);
//       }
//     }
//     yield put(finishLoading(type));
//   };
// };

export const createFetchActionWithKey = <P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  failureAction: PayloadActionCreator<IErrorPayloadWithKey>,
  params?: any,
  successFunc?: any,
  failureFunc?: any,
) => {
  return function* fetchApi(
    action: PayloadAction<{ key: string; [param: string]: any }>,
  ) {
    const { type, payload } = action;

    yield put(startLoading(type));

    try {
      const data: T & IResponse = yield call(
        api,
        params ? { ...params, ...payload } : payload,
      );
      const { status, message } = data;

      if (status && status === 'fail') {
        if (payload.failureFunc) {
          yield call<typeof payload.failureFunc>(
            payload.failureFunc,
            data,
            payload,
          );
        }

        yield put(failureAction({ key: payload.key, error: message }));

        if (failureFunc) {
          yield call<typeof failureFunc>(failureFunc, data);
        }
      } else {
        if (payload.successFunc) {
          yield call<typeof payload.successFunc>(
            payload.successFunc,
            data,
            payload,
          );
        }

        yield put(
          successAction({
            key: payload.key,
            data,
            requestPayload: { ...params, ...payload },
          }),
        );

        if (successFunc) {
          yield call<typeof successFunc>(successFunc, data, payload);
        }
      }
    } catch (e: any) {
      console.log('e', e, e.response);

      if (e.response?.status === 429) {
        window.location.replace('/error');
      }

      yield put(failureAction({ key: payload.key, error: e }));

      if (failureFunc) {
        yield call<typeof failureFunc>(failureFunc, e);
      }
    }
    yield put(finishLoading(type));
  };
};
